<template>
  <div class="choch allPage">
    <div class="choch__backdoor" @click="count"></div>
    <picture>
      <source
        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/choch_back_mob.svg`"
        media="(max-width: 1220px)"
      />
      <source
        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/choch_back.svg`"
        media="(max-width: 999999999px)"
      />
      <img class="choch__back" :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/choch_back.svg`" />
    </picture>
    <div class="content">
      <div class="choch__block">
        <div class="choch__title">Проводятся технические работы</div> 
        <div class="choch__description">
          Загляните к нам позже.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Plug",
  metaInfo: {
    title: "Проводятся технические работы",
  },
  data: () => ({
    counter: 0,
  }),
  methods: {
    count() {
      this.counter++
      if (this.counter === 5) {
        this.setCookie('access', 'true', 365)
        document.location.reload()
      }
    },
    setCookie(name, value, days) {
      var expires = ''
      if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/; Secure; HTTPOnly'
    },
  },
};
</script>

<style lang="scss" scoped>
.choch {
  margin-bottom: 0;
  position: relative;
  height: calc(100vh - 570px);
  min-height: 500px;
  background-image: linear-gradient(0deg, #dee2ee, #dee2ee),
    radial-gradient(
      80.48% 80.48% at 51.01% 52.72%,
      #ffffff 0%,
      #ffffff 0.01%,
      #dee2ee 64.06%,
      #dee2ee 100%
    );

    &.allPage {
      height: 100vh;
    }
  @media screen and (max-width: 1220px) {
    height: calc(100vh - 580px);
    // margin-bottom: -80px;
  }

  @media screen and (max-width: 797px) {
    height: calc(100vh - 703px);
  }

  &.inPage {
    margin-bottom: -125px;

    @media screen and (max-width: 1220px) {
      margin-bottom: -120px;
    }
  }

  &__backdoor {
    position: fixed;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 999999;
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .content {
    height: 100%;
  }

  &__title {
    margin-top: auto;
    margin-bottom: 30px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 32px;
    text-align: center;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  &__description {
    margin-top: 30px;
    margin-bottom: auto;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #3c4242;

     @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__caption {
    max-width: 60vw;
  }

  &__block {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>